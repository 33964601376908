import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Header from '../components/header'
import Footer from '../components/footer'
import Seo from '../components/seo'

const ListReachTrucks = ({data}) => {
    const entry = data.markdownRemark
    //console.log('entry: ', entry.frontmatter.products);
    return (
        <div className="page">
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname="/" />
            <Header />
            <div className="layout banner_bg grey">
                <div className="banner__container relative">
                    <div className="absolute banner_img">
                        <GatsbyImage image={entry.frontmatter.image.childImageSharp.gatsbyImageData} alt={entry.frontmatter.heading} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
                    </div>
                    <div className="banner relative">
                        <h2>{entry.frontmatter.section}</h2>
                    </div>
                </div>
            </div>
            <main className="layout list_entry grey">
                <div className="entry__container">
                    <div className="entry__flex flex__space-between">
                        <article className="article text">
                            <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                        </article>
                        <aside className="aside">
                            {entry.frontmatter.aside.map( (item, i) => (
                                <Link key={`item_` + i + ``} to={item.url} title={item.title}>
                                    <GatsbyImage image={item.image.childImageSharp.gatsbyImageData} alt={item.title} style={{ display: "block" }} imgStyle={{ objectFit: "contain" }} />
                                </Link>
                            ))}
                        </aside>
                    </div>
                </div>
            </main>
            <div className="layout category_entry grey">
                <div className="data__container">
                    <div>
                        <table className="data__table" border="0" cellPadding="0" cellSpacing="0">
                            <thead>
                            <tr>
                                {entry.frontmatter.products[0].model && (
                                    <th>Model</th>
                                )}
                                {entry.frontmatter.products[0].type && (
                                    <th>Type</th>
                                )}
                                {entry.frontmatter.products[0].load_capacity && (
                                    <th>Load Capacity</th>
                                )}
                                {entry.frontmatter.products[0].load_center && (
                                    <th>Load Center</th>
                                )}
                                {entry.frontmatter.products[0].overall_width && (
                                    <th>Overall Width</th>
                                )}
                                {entry.frontmatter.products[0].overall_length && (
                                    <th>Overall Length</th>
                                )}
                                {entry.frontmatter.products[0].overhead_guard_height && (
                                    <th>Overhead Guard Height</th>
                                )}
                                {entry.frontmatter.products[0].operating_aisle_width && (
                                    <th>Operating Aisle Width</th>
                                )}
                                {entry.frontmatter.products[0].truck_width && (
                                    <th>Truck Width</th>
                                )}
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                                {entry.frontmatter.products.map((product, i) => (
                                <tr key={`product_` + i + ``}>
                                    {product.model && (
                                        <td data-label="Model:&nbsp;">{product.model}</td>
                                    )}
                                    {product.type && (
                                        <td data-label="Type:&nbsp;">{product.type}</td>
                                    )}
                                    {product.load_capacity && (
                                        <td data-label="Load Capacity:&nbsp;">{product.load_capacity} kg</td>
                                    )}
                                    {product.load_center && (
                                        <td data-label="Load Center:&nbsp;">{product.load_center} mm</td>
                                    )}
                                    {product.overall_width && (
                                        <td data-label="Overall Width:&nbsp;">{product.overall_width} mm</td>
                                    )}
                                    {product.overall_length && (
                                        <td data-label="Overall Length:&nbsp;">{product.overall_length} mm</td>
                                    )}
                                    {product.overhead_guard_height && (
                                        <td data-label="Overhead Guard Height:&nbsp;">{product.overhead_guard_height} mm</td>
                                    )}
                                    {product.operating_aisle_width && (
                                        <td data-label="Operating Aisle Width:&nbsp;">{product.operating_aisle_width} mm</td>
                                    )}
                                    {product.truck_width && (
                                        <td data-label="Truck Width:&nbsp;">{product.truck_width} mm</td>
                                    )}
                                    <td><Link to={`/contact-us/?product=${entry.frontmatter.heading}`} className="btn__enquire">Enquire</Link></td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <main className="layout list_entry grey">
                <div className="entry__container">
                    <div className="entry__flex flex__space-between">
                        <figure className="article">
                            <GatsbyImage image={entry.frontmatter.product_image.childImageSharp.gatsbyImageData} alt={entry.frontmatter.heading} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
                        </figure>
                        <aside className="aside">
                            &nbsp;
                        </aside>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default ListReachTrucks;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
                section
				title
				page_title
				meta_description
                heading
                image {
                    childImageSharp {
                        gatsbyImageData(width: 1024, placeholder: NONE)
                    }
                }
                product_image {
                    childImageSharp {
                        gatsbyImageData(width: 1024, placeholder: NONE)
                    }
                }
                products {
                    model
                    type
                    load_capacity
                    load_center
                    overall_width
                    overall_length
                    overhead_guard_height
                    operating_aisle_width
                    truck_width
                }
                aside {
                    image {
                        childImageSharp {
                            gatsbyImageData(width: 272, placeholder: NONE)
                        }
                    }
                    title
                    url
                }
			}
			fields {
                slug
			}
			html
		}
    }
`
